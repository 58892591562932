<template>
	<div>
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'jobs-running'}">Jobs</router-link>
				</li>
			</ol>
		</nav>

		<h5>RUNNING JOBS</h5>
		<table class="table">
			<thead>
			<tr>
				<th>Name</th>
				<th>Type</th>
				<th>Date</th>
				<th>Status</th>
				<th>Progress</th>
			</tr>
			</thead>
			<tr v-for="job in jobs" :key="job.name">
				<td>
					<div class="font-weight-bold">{{job.project}}</div>
					<div class="text-small text-muted">{{job.configuration}}</div>
					<hr class="my-2">
					<div class="text-small">{{job.name}}</div>
				</td>
				<td class="text-uppercase">{{job.type}}</td>
				<td>
					<div>
						<strong>Created</strong> {{job.created}}
					</div>
					<div v-if="job.start">
						<strong>Start</strong> {{job.start}}
					</div>
				</td>
				<td class="text-uppercase">
					<spinner-status-label :status="job.status"></spinner-status-label>
				</td>
				<td>
					{{job.progress}}%
					<div class="progress" style="max-height: 5px">
						<div class="progress-bar progress-bar-animated progress-bar-striped" :style="{width: `${job.progress}%`}"></div>
					</div>
				</td>
				<td>
					<router-link class="btn btn-primary btn-sm" :to="job.link">Open {{job.name}}</router-link>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import api from '@/api'
import SpinnerStatusLabel from "../../components/spinner/spinner-status-label";

export default {
	name: "running",
	components: {SpinnerStatusLabel},
	data: () => ({jobs: null, interval: null}),
	mounted() {
		this.updateJobs()
		this.interval = setInterval(this.updateJobs, 10000)
	},
	destroyed() {
		clearInterval(this.interval)
	},
	methods: {
		async updateJobs() {
			this.jobs = await api.Jobs.getRunningJobs()
		}
	}
}
</script>

<style scoped>

</style>
